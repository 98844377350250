import CloseIcon from "@mui/icons-material/Close";
import {
    Button,
    Checkbox,
    Divider,
    Fade,
    IconButton,
    Skeleton,
    styled,
    Table,
    TableBody,
    TableRow,
    TextField,
    Tooltip,
} from "@mui/material";
import { compact, isNumber, uniqBy } from "lodash";
import { observer } from "mobx-react";
import * as React from "react";
import { useLocation } from "react-router";
import { MAX_SMS_INVITE, SEARCH_FIELD_MAX_WIDTH } from "../../../config";
import { useAccountTypeFilter } from "../../../hooks/useAccountTypeFilter";
import { useAddUserForm } from "../../../hooks/useAddUserForm";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { useContextMenu } from "../../../hooks/useContextMenu";
import { useDateRangeFilter } from "../../../hooks/useDateRangeFilter";
import { useDistributionChannels } from "../../../hooks/useDistributionChannels";
import { useDistributors } from "../../../hooks/useDistributors";
import { useMultiSelectionFilter } from "../../../hooks/useMultiSelectionFilter";
import { usePagination } from "../../../hooks/usePagination";
import { useQuery } from "../../../hooks/useQuery";
import { useRoles } from "../../../hooks/useRoles";
import { useTableSelection } from "../../../hooks/useTableSelection";
import { useToggleFilter } from "../../../hooks/useToggleFilter";
import { useUsers } from "../../../hooks/useUsers";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import {
    BaseUserRequest,
    PlatformType,
    PostCreateUserInvitePayloadInvitationTypeEnum,
    UserRequest,
} from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { ITab, UserPaginationOptions } from "../../../types";
import { pushRoute, replaceRoute, withQuery } from "../../app/router/history";
import { EmptyState } from "../../ui/EmptyState";
import { ErrorState } from "../../ui/ErrorState";
import { Filters } from "../../ui/filters/Filters";
import { LinkButton } from "../../ui/LinkButton";
import {
    CmsTableCellWithCheckbox,
    CmsTableContainer,
    NeutralButton,
    SiteContainer,
    TableLabel,
} from "../../ui/Primitives";
import { SearchField } from "../../ui/SearchField";
import { UserStatus, UserStatusMessages } from "../../ui/Status";
import { TableBodySkeleton } from "../../ui/TableBodySkeleton";
import { TableContextButton } from "../../ui/TableContextButton";
import { TableHeader, TableHeaderConfig } from "../../ui/TableHeader";
import { TableHeaderSkeleton } from "../../ui/TableHeaderSkeleton";
import { TabNavigation } from "../../ui/TabNavigation";
import { AccountTypes } from "../../util/AccountTypes";
import { Colors } from "../../util/Colors";
import { DialogVariant, getUserDialogConfiguration } from "../../util/Dialogs";
import { getFormattedDate, hasUserManagement, isErrorOfType } from "../../util/Helpers";
import { Icon } from "../../util/Icon";
import { UserRequestSources, UserRequestSourcesType } from "../../util/UserRequestSources";
import { UserDetailsSite } from "./UserDetailsSite";

const BulkActionContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 12px 3px 9px",
    marginTop: 16,
});

const SelectBanner = styled("div")({
    border: `1px solid ${Colors.GREY_200}`,
    borderRadius: 6,
    padding: "16px 16px",
    marginBottom: 12,
});

type Unprocessable = {
    countUnknown: number;
    countWithoutPhoneNumber: number;
    countWithoutEmail: number;
};

export const DEFAULT_TAB_ID = "-1";
export const DEFAULT_TAB_VALUE = t("screen.users.tab.label.default");

const TabsControlPanel = styled("div")({
    display: "flex",
    marginBottom: 32,
    border: `1px solid ${Colors.GREY_200}`,
    borderRadius: 8,
});

const StyledIconButton = styled(IconButton)(() => ({
    borderLeft: `1px solid ${Colors.GREY_200}`,
    background: Colors.GREY_50_BACKGROUND,
    borderRadius: "0 8px 8px 0",
    color: Colors.GREY_500,
    "&:hover": {
        color: Colors.ERROR,
    },
}));

const FADE_TIMEOUT = 400;
const TABLE_SKELETON_MAX_ROWS = 2;
export const UsersSite = observer(() => {
    const [contextUser, setContextUser] = React.useState<BaseUserRequest>();
    const [action, setAction] = React.useState<DialogVariant>("activate");
    const [isBulkAction, setIsBulkAction] = React.useState(false);
    const [unprocessableCounts, setUnprocessableCounts] = React.useState<Unprocessable>();
    const pagination = usePagination();
    const { distributors, getDistributorShortName, distributorsIsPending } = useDistributors(true);
    const { distributionChannels, distributionChannelsIsPending } = useDistributionChannels(true);
    const adminFilter = useToggleFilter({ name: "isAdmin", label: t("filter.label.admin") });
    const accountTypeFilter = useAccountTypeFilter("accountTypes");
    const query = useQuery();
    const location = useLocation();
    const [activeTab, setActiveTab] = React.useState<string | undefined>(undefined);
    const tabs = generalStore.tabs;
    const { rolesResponse } = useRoles();

    React.useEffect(() => {
        if (query.activeTabId && query.activeTabId !== DEFAULT_TAB_ID) {
            if (!generalStore.doesTabExist(query.activeTabId)) {
                generalStore.addTabs([{ id: query.activeTabId, values: [query.activeTabId] }]);
            }

            setActiveTab(query.activeTabId);
        } else {
            setActiveTab(undefined);
            generalStore.setUser(undefined);
            generalStore.setCustomerLocked(false);
        }
    }, [query]);

    const pushActiveTabToUrl = async (userId?: string | undefined) => {
        const params = query;
        params.activeTabId = userId;
        pushRoute(withQuery(location.pathname, params));
    };

    const replaceActiveTabUrl = async (userId?: string) => {
        const params = query;
        params.activeTabId = userId;
        replaceRoute(withQuery(location.pathname, params));
    };

    React.useEffect(() => {
        generalStore.setLoadingOverlayMs(300);
        return () => {
            generalStore.setLoadingOverlayMs(undefined);
            generalStore.setCustomerLocked(false);
            generalStore.setUser(undefined);
        };
    }, []);

    const statusFilter = useMultiSelectionFilter({
        name: "status",
        label: t("filter.label.status"),
        options: Object.entries(UserStatusMessages)
            .filter(([key]) => key !== "deleted")
            .map(([key, value]) => ({
                value: key,
                label: t(value),
            })),
    });

    const sourceFilter = useMultiSelectionFilter({
        name: "source",
        label: t("filter.label.source"),
        options: Object.entries(UserRequestSources).map(([key, value]) => ({
            value: key,
            label: t(value),
        })),
    });

    const distributorFilter = useMultiSelectionFilter({
        name: "distributors",
        label: t("filter.label.distributor"),
        options:
            uniqBy(distributors, (distributor) => distributor.externalVkorgID).map((distributor) => ({
                value: distributor.externalVkorgID,
                label: distributor.shortName,
            })) ?? [],
    });

    const distributionChannelFilter = useMultiSelectionFilter({
        name: "channels",
        label: t("filter.label.distributionChannel"),
        options:
            uniqBy(distributionChannels, (channel) => channel.externalID).map((channel) => ({
                value: channel.externalID,
                label: channel.externalID,
            })) ?? [],
    });

    const rolesFilter = useMultiSelectionFilter({
        name: "roles",
        label: t("filter.label.roles"),
        options:
            rolesResponse?.roles?.map((role) => ({
                value: role.id,
                label: role.name,
            })) ?? [],
    });

    const activeSinceFilter = useDateRangeFilter<UserPaginationOptions>({
        nameFrom: "activeSinceFrom",
        nameTo: "activeSinceTo",
        label: t("filter.label.activeSince"),
    });

    const lastActiveFilter = useDateRangeFilter<UserPaginationOptions>({
        nameFrom: "lastActiveFrom",
        nameTo: "lastActiveTo",
        label: t("filter.label.lastActive"),
    });

    const createdAtFilter = useDateRangeFilter<UserPaginationOptions>({
        nameFrom: "createdAtFrom",
        nameTo: "createdAtTo",
        label: t("table.label.createdAt"),
    });

    const filterResetComponents = compact([
        adminFilter.resetComponent,
        distributorFilter.resetComponent,
        distributionChannelFilter.resetComponent,
        statusFilter.resetComponent,
        sourceFilter.resetComponent,
        accountTypeFilter.resetComponent,
        rolesFilter.resetComponent,
        activeSinceFilter.resetComponent,
        lastActiveFilter.resetComponent,
        createdAtFilter.resetComponent,
    ]);

    const filterOptions = {
        search: pagination.search,
        [adminFilter.name]: adminFilter.value,
        [statusFilter.name]: statusFilter.values,
        [accountTypeFilter.name]: accountTypeFilter.values,
        [sourceFilter.name]: sourceFilter.values,
        [distributorFilter.name]: distributorFilter.values,
        [distributionChannelFilter.name]: distributionChannelFilter.values,
        [activeSinceFilter.nameFrom]: activeSinceFilter.valueFrom,
        [activeSinceFilter.nameTo]: activeSinceFilter.valueTo,
        [rolesFilter.name]: rolesFilter.values,
        [lastActiveFilter.nameFrom]: lastActiveFilter.valueFrom,
        [lastActiveFilter.nameTo]: lastActiveFilter.valueTo,
        [createdAtFilter.nameFrom]: createdAtFilter.valueFrom,
        [createdAtFilter.nameTo]: createdAtFilter.valueTo,
    };

    const FilterComponents = () => (
        <Filters>
            {adminFilter.component}
            {distributorFilter.component}
            {distributionChannelFilter.component}
            {statusFilter.component}
            {accountTypeFilter.component}
            {rolesFilter.component}
            {sourceFilter.component}
            {activeSinceFilter.component}
            {lastActiveFilter.component}
            {createdAtFilter.component}
        </Filters>
    );

    const { users, reloadUsers, isLoading, isInitialized, hasError } = useUsers(
        {
            limit: pagination.pageSize,
            offset: pagination.offset,
            orderDir: pagination.orderDir,
            orderBy: pagination.orderBy,
            ...filterOptions,
            status: filterOptions.status,
        },
        true,
        true,
    );

    const handleSubmitAddUser = async () => {
        try {
            await reloadUsers();
        } catch (error) {
            generalStore.setError(t("error.loadUsers"), error);
        }
    };

    const addUserDrawer = useAddUserForm({
        onSubmit: handleSubmitAddUser,
    });

    const { clearSelection, ...select } = useTableSelection(users?.userRequests ?? []);
    const [everythingSelected, setEverythingSelected] = React.useState(false);

    React.useEffect(() => {
        if (!select.allSelected) {
            // If one record is deselected clear everythingSelected
            setEverythingSelected(false);
        }
    }, [select.allSelected]);

    const handleSelectAll = () => {
        setEverythingSelected(true);
    };

    const handleDeselectAll = () => {
        setEverythingSelected(false);
        clearSelection();
    };

    const handleClickAddUser = () => {
        addUserDrawer.open();
    };

    const handleClickRow = async (iTab: ITab) => {
        if (!window.getSelection()?.toString().trim()) {
            if (!generalStore.doesTabExist(iTab.id)) {
                generalStore.addTabs([iTab]);
            }

            pushActiveTabToUrl(iTab.id);
        }
    };

    const handleUnprocessableResponse = (
        unprocessable: UserRequest[] = [],
        invitationType?: PostCreateUserInvitePayloadInvitationTypeEnum,
    ) => {
        if (isBulkAction && unprocessable.length > 0) {
            let totalCount = unprocessable.length;

            const countWithoutPhoneNumber =
                invitationType === "sms" ? unprocessable.filter((user) => !user.phone).length : 0;

            const countWithoutEmail =
                invitationType === "email" ? unprocessable.filter((user) => !user.email).length : 0;

            if (!invitationType) {
                setAction("unprocessableUnknown");
            } else {
                if (invitationType === "sms") {
                    totalCount -= countWithoutPhoneNumber;
                    setAction("unprocessableInviteSms");
                } else if (invitationType === "email") {
                    totalCount -= countWithoutEmail;
                    setAction("unprocessableInviteEmail");
                }
            }

            setUnprocessableCounts({
                countWithoutPhoneNumber,
                countWithoutEmail,
                countUnknown: totalCount,
            });

            confirmationDialog.open();
        }
    };

    const handleSubmitDeleteDialog = async () => {
        let selectedIds = everythingSelected ? [] : select.selectedIds;

        if (contextUser) {
            selectedIds = [contextUser.id];
        }

        try {
            generalStore.setIsLoading(true);

            const response = await API.deleteUsers(selectedIds, filterOptions);
            await reloadUsers();
            handleUnprocessableResponse(response.unprocessable);

            generalStore.setSuccessMessage(t("common.success"));
        } catch (error) {
            generalStore.setError(t("error.deleteUsers"), error);
        } finally {
            setContextUser(undefined);
            generalStore.setIsLoading(false);
        }
    };

    // Return either a submit handler for the invite or a tooltip why the action is disabled
    const handleSubmitInviteDialog = (invitationType: PostCreateUserInvitePayloadInvitationTypeEnum) => {
        if (invitationType === "sms") {
            if (
                select.selectedIds.length > MAX_SMS_INVITE ||
                (everythingSelected && (users?.total ?? 0) > MAX_SMS_INVITE)
            ) {
                // Multiselect case -> don't allow more than 20 users to be invited at once per SMS
                // Show tooltip to make this clear for the user
                return t("maxSmsInviteUsers.tooltip", { users: MAX_SMS_INVITE });
            }

            if (contextUser && (!contextUser.phone || hasUserManagement(contextUser))) {
                if (contextUser.phone && hasUserManagement(contextUser)) {
                    return t("userManagementInviteEmailOnly.tooltip");
                }

                // No phone -> disable phone invitation
                return;
            }
        } else {
            if (contextUser && !contextUser.email) {
                // No email -> disable email invitation
                return;
            }
        }

        return async () => {
            let selectedIds = everythingSelected ? [] : select.selectedIds;

            if (contextUser) {
                selectedIds = [contextUser.id];
            }

            try {
                generalStore.setIsLoading(true);

                const response = await API.postInviteUsers(selectedIds, filterOptions, invitationType);
                await reloadUsers();
                // TODO: Once BE returns unprocessable correctly with error reason, implement
                // multiselect case from here:
                // https://frauenthal.atlassian.net/wiki/spaces/FBPF/pages/239304717/FBP-1129+Tech+Spec+Mobilnummer+nderungen+in+FBP-Manager#Einladen%2FErneut-einladen-aus-Liste
                handleUnprocessableResponse(response.unprocessable, invitationType);

                generalStore.setSuccessMessage(t("common.success"));
            } catch (error) {
                if (isErrorOfType(error, "CANT_INVITE_USER_HAS_NO_PHONE")) {
                    generalStore.setError(t("error.userHasNoPhone"), error);
                } else if (isErrorOfType(error, "CANT_INVITE_USER_HAS_NO_EMAIL")) {
                    generalStore.setError(t("error.userHasNoEmail"), error);
                } else {
                    generalStore.setError(t("error.inviteUsers"), error);
                }
            } finally {
                setContextUser(undefined);
                generalStore.setIsLoading(false);
            }
        };
    };
    const handleSubmitActivateDialog = async () => {
        let selectedIds = everythingSelected ? [] : select.selectedIds;

        if (contextUser) {
            selectedIds = [contextUser.id];
        }

        try {
            generalStore.setIsLoading(true);

            const response = await API.putActivateUsers(selectedIds, filterOptions);
            await reloadUsers();

            handleUnprocessableResponse(response.unprocessable);

            generalStore.setSuccessMessage(t("common.success"));
        } catch (error) {
            generalStore.setError(t("error.activateUsers"), error);
        } finally {
            setContextUser(undefined);
            generalStore.setIsLoading(false);
        }
    };

    const handleSubmitDeactivateDialog = async () => {
        let selectedIds = everythingSelected ? [] : select.selectedIds;

        if (contextUser) {
            selectedIds = [contextUser.id];
        }

        try {
            generalStore.setIsLoading(true);

            const response = await API.putDeactivateUsers(selectedIds, filterOptions);
            await reloadUsers();
            handleUnprocessableResponse(response.unprocessable);

            generalStore.setSuccessMessage(t("common.success"));
        } catch (error) {
            generalStore.setError(t("error.deactivateUsers"), error);
        } finally {
            setContextUser(undefined);
            generalStore.setIsLoading(false);
        }
    };

    const handleSubmitApproveUserDialog = async () => {
        if (contextUser) {
            try {
                generalStore.setIsLoading(true);

                await API.postApproveUser(contextUser.id);
                await reloadUsers();
                generalStore.setSuccessMessage(t("common.success"));
            } catch (error) {
                generalStore.setError(t("error.deactivateUsers"), error);
            } finally {
                setContextUser(undefined);
                generalStore.setIsLoading(false);
            }
        }
    };

    const handleSubmitDenyUserDialog = async () => {
        if (contextUser) {
            try {
                generalStore.setIsLoading(true);

                await API.postDenyUser(contextUser.id);
                await reloadUsers();
                generalStore.setSuccessMessage(t("common.success"));
            } catch (error) {
                generalStore.setError(t("error.deactivateUsers"), error);
            } finally {
                setContextUser(undefined);
                generalStore.setIsLoading(false);
            }
        }
    };

    const handleSubmitRequestUserManagementDialog = async () => {
        if (contextUser) {
            try {
                generalStore.setIsLoading(true);
                await API.postCustomerRequestOptIn(contextUser.customerID);
                await reloadUsers();
                generalStore.setSuccessMessage(t("common.success"));
            } catch (error) {
                generalStore.setError(t("error.customerOptIn"), error);
            } finally {
                generalStore.setIsLoading(false);
            }
        }
    };

    const handleSubmitDeactivateUserManagementDialog = async () => {
        if (contextUser) {
            try {
                generalStore.setIsLoading(true);
                await API.postCustomerOptOut(contextUser.customerID);
                await reloadUsers();
                generalStore.setSuccessMessage(t("common.success"));
            } catch (error) {
                generalStore.setError(t("error.customerOptOut"), error);
            } finally {
                generalStore.setIsLoading(false);
            }
        }
    };

    const handleSubmitUnprocessableDialog = () => {
        setUnprocessableCounts(undefined);
    };

    const handleDownloadCsv = async () => {
        let selectedIds = everythingSelected ? [] : select.selectedIds;

        if (contextUser) {
            selectedIds = [contextUser.id];
        }

        try {
            generalStore.setIsLoading(true);
            await API.downloadUsersCSV(selectedIds, filterOptions);
        } catch (error) {
            generalStore.setError(t("error.downloadCsvUsers"), error);
        } finally {
            generalStore.setIsLoading(false);
        }
    };

    const confirmationDialogSubmitHandler: { [key in DialogVariant]: ((() => void) | string | undefined)[] } = {
        delete: [handleSubmitDeleteDialog],
        invite: [handleSubmitInviteDialog("email"), handleSubmitInviteDialog("sms")],
        activate: [handleSubmitActivateDialog],
        deactivate: [handleSubmitDeactivateDialog],
        approve: [handleSubmitApproveUserDialog],
        deny: [handleSubmitDenyUserDialog],
        requestUserManagement: [handleSubmitRequestUserManagementDialog],
        deactivateUserManagement: [handleSubmitDeactivateUserManagementDialog],
        reinvite: [handleSubmitInviteDialog("email"), handleSubmitInviteDialog("sms")],
        unprocessableUnknown: [handleSubmitUnprocessableDialog],
        unprocessableInviteSms: [handleSubmitUnprocessableDialog],
        unprocessableInviteEmail: [handleSubmitUnprocessableDialog],
        forceUserManagement: [
            () => {
                // dummy handler to fulfill type without setting key to optional
            },
        ],
        resetPassword: [
            () => {
                // dummy handler to fulfill type without setting key to optional
            },
        ],
        resetPasswordByMethod: [
            () => {
                // dummy handler to fulfill type without setting key to optional
            },
        ],
    };

    const getConfirmationDialogUnknownCount = () => {
        if (unprocessableCounts && isNumber(unprocessableCounts?.countUnknown)) {
            return unprocessableCounts.countUnknown;
        } else {
            return everythingSelected ? users?.total : select.selectedIds.length;
        }
    };

    // Get submit handlers for confirmation dialog
    const submitHandlers = confirmationDialogSubmitHandler[action];
    const submitHandler = submitHandlers[0];
    const secondaryAction = submitHandlers.length > 1 && submitHandlers[1];

    const confirmationDialog = useConfirmationDialog({
        ...getUserDialogConfiguration(action, {
            user: contextUser,
            multi: isBulkAction,
            count: getConfirmationDialogUnknownCount(),
            countWithoutEmail: unprocessableCounts?.countWithoutEmail,
            countWithoutPhoneNumber: unprocessableCounts?.countWithoutPhoneNumber,
        }),
        onSubmit: typeof submitHandler === "function" ? submitHandler : undefined, // handler is function or string (= tooltip)
        submitTooltip: typeof submitHandler === "string" ? submitHandler : undefined,
        onSecondaryAction: !!secondaryAction && typeof secondaryAction === "function" ? secondaryAction : undefined,
        secondaryActionTooltip: !!secondaryAction && typeof secondaryAction === "string" ? secondaryAction : undefined,
        onCancel: () => {
            setContextUser(undefined);
        },
    });

    const handleClickUserAction = (action: DialogVariant, isBulk?: boolean) => {
        setIsBulkAction(!!isBulk);
        setAction(action);
        confirmationDialog.open();
    };

    const isBulkActionAllowed = (action: DialogVariant) => {
        const selectedUsers = users?.userRequests?.filter((user) => select.selectedIds.includes(user.id));

        switch (action) {
            case "delete": {
                return selectedUsers?.every((user) => user.canBeDeleted);
            }
            case "invite": {
                return selectedUsers?.every((user) => user.canBeInvited);
            }
            case "activate": {
                return selectedUsers?.every((user) => user.canBeActivated);
            }
            case "deactivate": {
                return selectedUsers?.every((user) => user.canBeDeactivated);
            }
            default:
                return false;
        }
    };

    const headerFields: TableHeaderConfig[] = [
        { column: "checkbox", style: { width: 0 } },
        { column: "customerNumber", label: "table.label.customerNumber", style: { width: 0 } },
        { column: "isAdmin", label: "table.label.admin", style: { width: 0 } },
        { column: "lastName", label: "table.label.lastName" },
        { column: "firstName", label: "table.label.firstName" },
        { column: "companyName", label: "table.label.companyName" },
        { column: "email", label: "table.label.email", style: { width: 0 } },
        { column: "phone", label: "table.label.phone" },
        // { column: "alias", label: "table.label.alias" },
        { column: "salesRepresentative", label: "table.label.salesRepresentative" },
        { column: "accountType", label: "table.label.accountType" },
        // { column: "userIdentifier", label: "table.label.userIdentifier", style: { width: 0 } },
        // { column: "position", label: "table.label.position" },
        { column: "source", label: "table.label.source", style: { width: 0 } },
        { column: "createdAt", label: "table.label.createdAt", style: { width: 0 } },
        { column: "activationPlatform", label: "table.label.activationPlatform", style: { width: 0 } },
        //{ column: "externalVkorgID", label: "table.label.vko" },
        { column: "vkoShortName", label: "table.label.vko" },
        { column: "externalVtwegID", label: "table.label.vtChannel", style: { width: 0 } },
        { column: "status", label: "table.label.status", style: { width: 0 } },
        { column: "contextMenu", style: { width: 0 } },
    ];

    const contextMenu = useContextMenu<BaseUserRequest>((user) => {
        if (user) {
            return compact([
                user.canBeInvited && {
                    title: user.status === "invited" ? t("button.reinvite") : t("button.invite"),
                    onClick: () => {
                        setContextUser(user);
                        handleClickUserAction(user.status === "invited" ? "reinvite" : "invite");
                    },
                },
                user.canBeDeactivated && {
                    title: t("button.deactivate"),
                    onClick: () => {
                        setContextUser(user);
                        handleClickUserAction("deactivate");
                    },
                },

                user.canBeActivated && {
                    title: t("button.activate"),
                    onClick: () => {
                        setContextUser(user);
                        handleClickUserAction("activate");
                    },
                },
                user.canBeAccepted && {
                    title: t("button.approve"),
                    onClick: () => {
                        setContextUser(user);
                        handleClickUserAction("approve");
                    },
                },
                user.canBeDenied && {
                    title: t("button.deny"),
                    onClick: () => {
                        setContextUser(user);
                        handleClickUserAction("deny");
                    },
                },
                user.canBeDeleted && {
                    title: t("button.delete"),
                    onClick: () => {
                        setContextUser(user);
                        handleClickUserAction("delete");
                    },
                },
                !user.customer?.optIn && {
                    title: t("button.requestUserManagement"),
                    onClick: () => {
                        setContextUser(user);
                        handleClickUserAction("requestUserManagement");
                    },
                },
                user.customer?.optIn && {
                    title: t("button.deactivateUserManagement"),
                    onClick: () => {
                        setContextUser(user);
                        handleClickUserAction("deactivateUserManagement");
                    },
                },
            ]);
        }

        return [];
    });

    function getActivationPlatformLabel(activationPlatform: PlatformType | string | undefined): string {
        switch (activationPlatform) {
            case "b2bapp":
                return t("activationPlatform.b2bapp");
            case "b2bwebshop":
                return t("activationPlatform.b2bwebshop");
            case "fbpmanager":
                return t("activationPlatform.fbpmanager");
            case "partnerportal":
                return t("activationPlatform.partnerportal");
            default:
                return "-";
        }
    }
    const tableBody = (
        <TableBody>
            {users?.userRequests?.map((user) => {
                return (
                    <TableRow key={user.id}>
                        {headerFields.map(({ column }) => {
                            let label = (user as any)[column] ?? "";

                            if (column === "checkbox") {
                                return (
                                    <CmsTableCellWithCheckbox padding="checkbox" key={column}>
                                        <Checkbox
                                            onChange={(event, checked) => {
                                                select.toggleSelection(user.id);
                                            }}
                                            onClick={(event) => event.stopPropagation()}
                                            color="primary"
                                            checked={select.isSelected(user.id)}
                                            size="small"
                                        />
                                    </CmsTableCellWithCheckbox>
                                );
                            } else if (column === "contextMenu") {
                                return (
                                    <TableContextButton
                                        key={column}
                                        onClick={(event) => {
                                            contextMenu.open(event, user);
                                        }}
                                    />
                                );
                            } else if (column === "status") {
                                label = <UserStatus status={user[column]} />;
                            } else if (column === "isAdmin") {
                                label = hasUserManagement(user) ? (
                                    <Icon name="checkmarkCircle" color={Colors.SECONDARY_600} />
                                ) : undefined;
                            } else if (column === "source") {
                                const userRequestSourceLabel =
                                    UserRequestSources[user[column] as keyof UserRequestSourcesType];
                                if (userRequestSourceLabel) {
                                    label = t(userRequestSourceLabel);
                                } else {
                                    console.error("No label specified for source:", user[column]);
                                    label = user[column];
                                }
                            } else if (column === "accountType") {
                                const accountTypeLabel = AccountTypes[user[column]];
                                if (accountTypeLabel) {
                                    label = t(accountTypeLabel);
                                } else {
                                    console.error("No label specified for accountType:", user[column]);
                                    label = user[column];
                                }
                            } else if (column === "customerNumber") {
                                label = user.customer?.externalId;
                            } else if (column === "position") {
                                label = user.corporatePosition?.role.name;

                                if (user.appUserProfile?.role) {
                                    label = user.appUserProfile?.role.name;
                                }
                            } else if (column === "firstName") {
                                label = user.firstName;

                                if (user.appUserProfile) {
                                    label = user.appUserProfile.firstName;
                                }
                            } else if (column === "lastName") {
                                label = user.lastName;

                                if (user.appUserProfile) {
                                    label = user.appUserProfile.lastName;
                                }
                            } else if (column === "createdAt") {
                                label = getFormattedDate(user?.createdAt, "DD.MM.YYYY HH:mm:ss");
                            } else if (column === "activationPlatform") {
                                label = getActivationPlatformLabel(user.appUserProfile?.activationPlatform);
                            } else if (column === "email") {
                                label = user.email;

                                if (user.appUserProfile) {
                                    label = user.appUserProfile.email;
                                }
                            } else if (column === "phone") {
                                label = user.phone;

                                if (user.appUserProfile) {
                                    label = user.appUserProfile.phoneNumber;
                                }
                            } else if (column === "vkoShortName") {
                                label = getDistributorShortName(user.distributorID);
                            } else if (column === "companyName") {
                                label = user.customer?.name;
                            } else if (column === "salesRepresentative") {
                                label = user.salesRepresentative;
                            }

                            return (
                                <CmsTableCellWithCheckbox
                                    key={column}
                                    onClick={() =>
                                        handleClickRow({
                                            id: user.id,
                                            values: [user.externalCustomerID, `${user.lastName} ${user.firstName}`],
                                        })
                                    }
                                    style={{
                                        cursor: "pointer",
                                        opacity: user.customer?.optIn ? 1 : 0.5,
                                        maxWidth:
                                            column !== "status" &&
                                            column !== "email" &&
                                            column !== "customerNumber" &&
                                            column !== "userIdentifier"
                                                ? 100
                                                : undefined,
                                    }}
                                >
                                    <TableLabel style={{ maxWidth: "100%", cursor: "auto", width: "fit-content" }}>
                                        {label}
                                    </TableLabel>
                                </CmsTableCellWithCheckbox>
                            );
                        })}
                    </TableRow>
                );
            })}
        </TableBody>
    );

    const isEmpty = users && users.total === 0;

    const handleTabChange = (event: React.SyntheticEvent, userId: string) => {
        if (userId !== activeTab) {
            pushActiveTabToUrl(userId !== DEFAULT_TAB_ID ? userId : undefined);
        }
    };

    const handleCloseTab = (tabId: string) => {
        // chooses new active tab on closing current active tab
        if (tabId === activeTab) {
            const index = tabs.findIndex((tab) => tab.id === tabId);
            const newTabId = index === tabs.length - 1 ? index - 1 : index + 1;
            setActiveTab(newTabId < 0 ? undefined : tabs[newTabId].id);
            replaceActiveTabUrl(newTabId < 0 ? undefined : tabs[newTabId].id);
        }

        generalStore.removeTabs([tabId]);
    };

    const handleOpenLinkedUser = (tabId: string) => {
        pushActiveTabToUrl(tabId);
    };

    return (
        <SiteContainer style={{ padding: "24px 64px" }}>
            <TabsControlPanel>
                <TabNavigation
                    style={{
                        width: "100%",
                    }}
                    tabs={tabs}
                    defaultTab={{ values: [DEFAULT_TAB_VALUE], id: DEFAULT_TAB_ID }}
                    activeTab={activeTab}
                    onChange={handleTabChange}
                    onCloseTab={handleCloseTab}
                />

                {tabs.length > 0 && (
                    <Tooltip title={t("button.closeAllTabs.tooltip")}>
                        <StyledIconButton
                            color="error"
                            size="small"
                            onClick={() => {
                                generalStore.setTabs([]);
                                pushActiveTabToUrl(undefined);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </StyledIconButton>
                    </Tooltip>
                )}
            </TabsControlPanel>

            {activeTab ? (
                <UserDetailsSite
                    userId={activeTab}
                    isActive
                    reloadUsers={reloadUsers}
                    setTab={generalStore.updateTabValue}
                    onCloseUserDetails={handleCloseTab}
                    onOpenLinkedUser={handleOpenLinkedUser}
                    key={activeTab}
                />
            ) : (
                <div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            flexWrap: "nowrap",
                            flexDirection: "row-reverse",
                            gap: 16,
                            marginTop: 24,
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "stretch",
                                flexGrow: 1,
                                justifyContent: "end",
                                gap: 16,
                                flexWrap: "wrap",
                                flexDirection: "row-reverse",
                            }}
                        >
                            {!isInitialized ? (
                                <Skeleton animation="wave" variant="rounded" style={{ maxWidth: "100%" }}>
                                    <Button variant="contained">{t("screen.users.button.add.text")}</Button>
                                </Skeleton>
                            ) : (
                                <Button onClick={handleClickAddUser} variant="contained">
                                    {t("screen.users.button.add.text")}
                                </Button>
                            )}
                            <div style={{ maxWidth: SEARCH_FIELD_MAX_WIDTH, width: "100%" }}>
                                {!isInitialized ? (
                                    <Skeleton animation="wave" variant="rounded" style={{ maxWidth: "100%" }}>
                                        <TextField fullWidth />
                                    </Skeleton>
                                ) : (
                                    <SearchField
                                        placeholder={t("screen.users.search.placeholder")}
                                        onChange={pagination.onChangeSearch}
                                        onEnter={pagination.onChangeSearch}
                                        value={pagination.search}
                                        style={{ width: "100%", flexShrink: 0 }}
                                        readOnly={isLoading}
                                        fetchOnDeletion
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            {distributorsIsPending || distributionChannelsIsPending || !isInitialized ? (
                                <Skeleton animation="wave" variant="rounded">
                                    <FilterComponents />
                                </Skeleton>
                            ) : (
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <FilterComponents />
                                </div>
                            )}
                        </div>
                    </div>

                    <Divider style={{ marginTop: 16, marginBottom: 16, opacity: isInitialized ? 1 : 0 }} />
                    {isInitialized && filterResetComponents.length > 0 && <Filters>{filterResetComponents}</Filters>}

                    {hasError && (
                        <ErrorState
                            iconName="warning"
                            title={t("screen.users.errorScreen.title")}
                            description={t("error.loadUsers")}
                            redoAction={reloadUsers}
                        />
                    )}
                    {isInitialized && !isLoading && isEmpty && !hasError && (
                        <EmptyState
                            iconName="mail"
                            title={t("screen.users.emptyScreen.title")}
                            description={t("screen.users.emptyScreen.description")}
                        />
                    )}

                    {!isEmpty && !hasError && (
                        <>
                            <Fade in timeout={FADE_TIMEOUT}>
                                <BulkActionContainer>
                                    <div style={{ display: "flex", flexGrow: 1, gap: 24 }}>
                                        {isInitialized && !isEmpty ? (
                                            <Checkbox
                                                color="primary"
                                                onChange={select.toggleSelectAll}
                                                checked={select.allSelected}
                                                indeterminate={select.someSelected}
                                                style={{
                                                    padding: 4,
                                                    border: `1px solid ${Colors.GREY_200}`,
                                                    borderRadius: 6,
                                                }}
                                                data-id="select-all-checkbox"
                                                size="small"
                                                disabled={isLoading}
                                            />
                                        ) : (
                                            <Skeleton animation="wave" variant="rounded">
                                                <Checkbox size="small" />
                                            </Skeleton>
                                        )}

                                        {(select.someSelected || select.allSelected) && (
                                            <div style={{ display: "flex", flexGrow: 1, gap: 8 }}>
                                                {isBulkActionAllowed("delete") && (
                                                    <NeutralButton
                                                        disabled={isLoading}
                                                        onClick={() => handleClickUserAction("delete", true)}
                                                    >
                                                        {t("screen.users.bulkAction.delete")}
                                                    </NeutralButton>
                                                )}
                                                {isBulkActionAllowed("invite") && (
                                                    <NeutralButton
                                                        disabled={isLoading}
                                                        onClick={() => handleClickUserAction("invite", true)}
                                                    >
                                                        {t("screen.users.bulkAction.invite")}
                                                    </NeutralButton>
                                                )}
                                                {isBulkActionAllowed("activate") && (
                                                    <NeutralButton
                                                        disabled={isLoading}
                                                        onClick={() => handleClickUserAction("activate", true)}
                                                    >
                                                        {t("screen.users.bulkAction.activate")}
                                                    </NeutralButton>
                                                )}
                                                {isBulkActionAllowed("deactivate") && (
                                                    <NeutralButton
                                                        disabled={isLoading}
                                                        onClick={() => handleClickUserAction("deactivate", true)}
                                                    >
                                                        {t("screen.users.bulkAction.deactivate")}
                                                    </NeutralButton>
                                                )}
                                                <NeutralButton disabled={isLoading} onClick={() => handleDownloadCsv()}>
                                                    {t("screen.users.bulkAction.downloadCsv")}
                                                </NeutralButton>
                                            </div>
                                        )}
                                    </div>
                                    {!isInitialized || isEmpty ? (
                                        <Skeleton animation="wave" variant="text">
                                            {pagination.component(users?.total ?? 0)}
                                        </Skeleton>
                                    ) : (
                                        pagination.component(users?.total ?? 0)
                                    )}
                                </BulkActionContainer>
                            </Fade>
                            {select.allSelected && users && users.total > pagination.pageSize && (
                                <SelectBanner>
                                    <p
                                        className="body2"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: 4,
                                        }}
                                    >
                                        {!everythingSelected && (
                                            <>
                                                <span>
                                                    {t("screen.users.selectAllBanner.inactive.message", {
                                                        count: (<b>{select.selectedIds.length}</b>) as any,
                                                    })}{" "}
                                                </span>
                                                <LinkButton
                                                    onClick={handleSelectAll}
                                                    color="primary"
                                                    style={{ fontSize: 14, padding: 0 }}
                                                >
                                                    {t("screen.users.selectAllBanner.inactive.button", {
                                                        count: users.total,
                                                    })}
                                                </LinkButton>
                                            </>
                                        )}
                                        {everythingSelected && (
                                            <>
                                                <span>
                                                    {t("screen.users.selectAllBanner.active.message", {
                                                        count: (<b>{users.total}</b>) as any,
                                                    })}{" "}
                                                </span>
                                                <LinkButton
                                                    onClick={handleDeselectAll}
                                                    color="primary"
                                                    style={{ fontSize: 14, padding: 0 }}
                                                >
                                                    {t("screen.users.selectAllBanner.active.button")}
                                                </LinkButton>
                                            </>
                                        )}
                                    </p>
                                </SelectBanner>
                            )}

                            <Fade in timeout={FADE_TIMEOUT}>
                                <CmsTableContainer>
                                    <Table>
                                        {!isInitialized || (isInitialized && isEmpty && isLoading) ? (
                                            <>
                                                <TableHeaderSkeleton columns={headerFields.length} />
                                                <TableBodySkeleton
                                                    rows={
                                                        pagination.pageSize > TABLE_SKELETON_MAX_ROWS
                                                            ? TABLE_SKELETON_MAX_ROWS
                                                            : pagination.pageSize
                                                    }
                                                    columns={headerFields.length}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <TableHeader
                                                    headerFields={headerFields}
                                                    orderBy={pagination.orderBy}
                                                    orderDir={pagination.orderDir}
                                                    onChangeOrder={pagination.onChangeOrder}
                                                    hasCheckbox
                                                />

                                                {tableBody}
                                            </>
                                        )}
                                    </Table>
                                </CmsTableContainer>
                            </Fade>
                            {contextMenu.component}
                        </>
                    )}
                    {addUserDrawer.component}
                    {confirmationDialog.component}
                </div>
            )}
        </SiteContainer>
    );
});
